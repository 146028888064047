
import { defineComponent, onMounted, ref, reactive } from "vue";
// import { defineComponent, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import Loading from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";

interface NewData {
  file: string;
  file2: string;
  file3: string;
  typeAktifitas: string;
  documentType: string;
  idSchool: string;
  fileName: string;
}

interface items {
  legalType: any;
  legalAktifitas: any;
  listSchool: any;
  fileInputKey: any;
  errorschoolId: string;
  errorAktifitas: string;
  errorType: string;
  errorFile: string;
  errorFile2: string;
  errorFile3: string;
}

export default defineComponent({
  name: "new-target-modal",
  components: {
    Loading,
    Multiselect,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      legalType: [],
      legalAktifitas: [],
      listSchool: [],
      fileInputKey: 0,
      errorschoolId: "",
      errorAktifitas:"",
      errorType: "",
      errorFile: "",
      errorFile2: "",
      errorFile3: "",
    });

    const targetData = reactive<NewData>({
      file: "",
      file2: "",
      file3: "",
      typeAktifitas: "",
      documentType: "",
      idSchool: "",
      fileName: "",
    });

    function handleFileUpload(event) {
      console.log(event.target.files[0].name);
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        console.log(myArray[1]);
        if(myArray[1] == 'docx'){
          targetData.file = event.target.files[0];
        }else{
          toast.error("File Hanya .docx");
          items.errorFile = "File Wajib Isi dan File Hanya .docx";
        }
      } else {
        toast.error("File Kosong");
      }
    }

    const setAccess = () => {
      targetData.documentType = 'PKS'
    };

    const getListAktifitas = () => {
      
      isLoading.value = true;
      items.legalAktifitas = JSON.parse(localStorage.getItem("arrayDraf")!);
      // const dataAktifitas = [
      //     {
      //       _id: 'upload',
      //       name: 'Upload Draft Template',
      //     },
      //     {
      //       _id: 'download',
      //       name: 'Download Draft Template',
      //     },
      //   ];

      // items.legalAktifitas = dataAktifitas;
      isLoading.value = false;
      console.log('test');
      console.log(items.legalAktifitas);
    };

    const getLegalType = () => {
      const dataType = ['PKS'];
      items.legalType = dataType;
      // ApiService.getWithoutSlug("crmv2/main_legal/legal/legal/combo/type").then(
      //   (response) => {
      //     items.legalType = response.data;
      //     console.log(items.legalType);
      //   }
      // );
    };

    const submit = () => {
      if (
        targetData.typeAktifitas === "" ||
        targetData.documentType === ""
      ) {
        if (targetData.typeAktifitas === "") {
          items.errorAktifitas = "Tipe Aktifitas Wajib Diisi";
        }
        if (targetData.file === "") {
          items.errorFile = "File Wajib Diisi";
        }
        if (targetData.documentType === "") {
          items.errorType = "Tipe Wajib Diisi";
        }
        toast.error("Form belum lengkap");

      } else {

        if(targetData.typeAktifitas === "upload"){
             if (targetData.file === "" ) {
                if (targetData.file === "") {
                  if(items.errorFile){
                  }else{
                    items.errorFile = "File Wajib Diisi";
                  }
                }
                toast.error("Form belum lengkap");
              } else {
                
                    isLoading.value = true;
                let formData = new FormData();

                formData.append("documentFile", targetData.file);
                ApiService.postWithData(
                  "crmv2/main_legal/draft_legal/legal/upload_draft_template?documentType=" +
                    targetData.documentType,
                  formData
                )
                  .then((res) => {
                    toast.success("Berhasil Upload Dokumen");
                    targetData.file = "";
                    targetData.documentType = "";
                    targetData.idSchool = "";
                    targetData.typeAktifitas = "";
                    targetData.fileName = "";
                    items.fileInputKey++;
                    hideModal(uploadModalRef.value);
                    isLoading.value = false;
                    // items.disableButton = false;
                    router.push({
                      path: "/superadmin/legal/legalitas",
                    });

                    // location.reload();

                  })
                  .catch((e) => {
                    isLoading.value = false;
                    toast.error(e.response.data.detail);
                  });
              }
        }else{
            isLoading.value = true;
            items.errorAktifitas = "";
            items.errorFile = "";
            items.errorType = "";

            loading.value = true;

            loading.value = false;
            let formData = new FormData();

            ApiService.getDownload(
              "crmv2/main_legal/draft_legal/legal/download_draft_template?documentType=" +
                targetData.documentType
            ).then((response) => {
              // this.handleBlobResponse(response.data, item, extension);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;

                // console.log(res.data);
                var extension = 'docx';
                link.setAttribute("download", "Dokumen " + targetData.documentType +"." + extension); // nama file dan extension sesuaikan dengan file yang di download
                document.body.appendChild(link);
                link.click();

                targetData.file = "";
                targetData.documentType = "";
                targetData.idSchool = "";
                targetData.typeAktifitas = "";
                targetData.fileName = "";
                items.fileInputKey++;
                // toast.success("Berhasil Upload Dokumen");
                hideModal(uploadModalRef.value);
                isLoading.value = false;

                router.push({
                  path: "/superadmin/legal/legalitas",
                });

                // location.reload();

                toast.success("Sukses Unduh File");
                isLoading.value = false;
            })
            .catch((e) => {
              console.log(e);
                toast.error("Gagal Unduh File");
                isLoading.value = false;
            });            
          }
        }
    };

    const backEvent = () => {
      targetData.file = "";
      targetData.documentType = "";
      targetData.typeAktifitas = "";
      targetData.fileName = "";
      items.fileInputKey++;

      items.errorAktifitas = "";
      items.errorType = "";
      items.errorFile = "";
      items.errorFile2 = "";
      items.errorFile3 = "";
    };

    onMounted(() => {
      getLegalType();
      getListAktifitas();
      setAccess();
    });

    return {
      targetData,
      submit,
      backEvent,
      loading,
      formRef,
      items,
      uploadModalRef,
      handleFileUpload,
      getLegalType,
      getListAktifitas,
      setAccess,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },

  methods: {
    submit1() {
      console.log("testsubmit");
      this.$emit("enlarge-text");
    },
  },
});
