
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useRouter, useRoute } from "vue-router";
import { checkAuth } from "@/core/config/custom";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listSekolah: [],
      listStatus: [],
      listStatusIndo: [],
      listType: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      role: "",
      schoolId: "",
      schoolName: '',
      filterTipe: "",
      filterStatus: "",

      detail: {
        nameSchool: "",
        legalCreatorName: "",
        typ: "",
        startDate: "",
        expiredDate: "",
        status: "",
        createTime: "",
        updateTime: "",
      },

      aksesUpload: false,
      aksesdraft: false,
      aksesDownload: false,
      aksesEdit: false,
      aksesDelete: false,

      documentItem: [],

      getExtensionFromDetail: '.docx',

      showFilter: false,
      timer: 0,
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    this.schoolName = JSON.parse(localStorage.getItem("schoolName")!);
    this.cekRoleAkses();
    this.getListSekolah();
    this.getLegalStatus();
    this.getLegalStatusIndo();
    this.getLegalType();


    this.getData(this.paging.size, this.paging.page);
    setCurrentPageBreadcrumbs("Legalitas", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksesUpload = true;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = true;
          this.aksesDelete = true;
          this.setAktifitasDrafTemplate("all");
          break;
        case "SALES_LEADER":
          this.aksesUpload = true;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = true;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "SALES_AREA":
          this.aksesUpload = true;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = true;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "CS_LEADER":
          this.aksesUpload = false;
          this.aksesdraft = false;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "CS_MEMBER":
          this.aksesUpload = false;
          this.aksesdraft = false;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "IMPLEMENTATOR_LEADER":
          this.aksesUpload = false;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "ADMIN_FINANCE":
          this.aksesUpload = false;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "IMPLEMENTATOR_MEMBER":
          this.aksesUpload = false;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "ADMIN_LEGAL":
          this.aksesUpload = true;
          this.aksesdraft = true;
          this.aksesDownload = true;
          this.aksesEdit = true;
          this.aksesDelete = true;
          this.setAktifitasDrafTemplate("all");
          break;
        case "MARKETING_LEADER":
          this.aksesUpload = false;
          this.aksesdraft = false;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
        case "MARKETING_MEMBER":
          this.aksesUpload = false;
          this.aksesdraft = false;
          this.aksesDownload = true;
          this.aksesEdit = false;
          this.aksesDelete = false;
          this.setAktifitasDrafTemplate("download");
          break;
      }
    },
    setAktifitasDrafTemplate(val) {
      var arrayDraf: any[] = [];
      if (val == "all") {
        arrayDraf = [
          {
            _id: "upload",
            name: "Upload Draft Template",
          },
          {
            _id: "download",
            name: "Download Draft Template",
          },
        ];
      } else if (val == "download") {
        arrayDraf = [
          {
            _id: "download",
            name: "Download Draft Template",
          },
        ];
      } else if (val == "upload") {
        arrayDraf = [
          {
            _id: "upload",
            name: "Upload Draft Template",
          },
        ];
      } else {
        arrayDraf = [];
      }
      console.log(val);
      console.log(arrayDraf);
      localStorage.setItem("arrayDraf", JSON.stringify(arrayDraf));
    },

    getData(size, page) {
      this.isLoading = true;

      let searchTipe = "";
      let searchStatus = "";
      let search = "";
      let valschoolId = "";
      if (this.schoolId) {
        valschoolId = this.schoolId;
      }
      if (this.name) {
        search = "name=" + this.name + "&";
      } else {
        search;
      }
      if (this.filterTipe) {
        searchTipe = "documentType=" + this.filterTipe + "&";
      } else {
        searchTipe;
      }
      if (this.filterStatus) {
        searchStatus =
          "documentStatus=" + this.changeBahasa2(this.filterStatus) + "&";
      } else {
        searchStatus;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/all?" +
        search +
        searchTipe +
        searchStatus +
        "page=" +
        page +
        "&size=" +
        size +
        "&sort=name&dir=1&schoolId=" +
        valschoolId
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },
    getLegalStatus() {
      ApiService.getWithoutSlug("crmv2/main_legal/legal/legal/combo/status")
        .then(({ data }) => {
          this.listStatus = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getLegalStatusIndo() {
      ApiService.getWithoutSlug("crmv2/main_legal/legal/legal/combo/type/indo")
        .then(({ data }) => {
          this.listStatusIndo = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getLegalType() {
      ApiService.getWithoutSlug("crmv2/main_legal/legal/legal/combo/type")
        .then(({ data }) => {
          this.listType = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    pilihSekolah(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      var namaSekolah = "";
      if (event) {
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        namaSekolah = school.name;
      } else {
        this.schoolId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("schoolName", JSON.stringify(namaSekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    pilihTipe(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.filterTipe = event;
      } else {
        this.filterTipe = "";
      }
      // this.getQtyStudent();
      // localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },
    pilihStatus(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      var rslt = "";
      if (event) {
        this.filterStatus = event;
      } else {
        this.filterStatus = "";
      }
      // this.getQtyStudent();
      // localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      ApiService.delete(
        "crmv2/main_legal/legal/legal/" + item._id + "/" + item.documentId
      )
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
          this.isLoading = false;
        });
    },

    saveDocItem(item) {
      this.documentItem = item;
    },

    changeBahasa(val) {
      // console.log(val)
      switch (val) {
        case "Waiting":
          return "Menunggu";
          break;
        case "Process":
          return "Proses";
          break;
        case "Reject":
          return "Ditolak";
          break;
        case "Cancel":
          return "Batal";
          break;
        case "Approve":
          return "Disetujui";
          break;
      }
    },

    changeBahasa2(val) {
      switch (val) {
        case "Menunggu":
          return "Waiting";
          break;
        case "Proses":
          return "Process";
          break;
        case "Ditolak":
          return "Reject";
          break;
        case "Batal":
          return "Cancel";
          break;
        case "Disetujui":
          return "Approve";
          break;
      }
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    clickview(val) {
      // this.getDetail(val.id);
      console.log(val);
      this.detail.nameSchool = val.name;
      this.detail.legalCreatorName = val.legalCreatorName;
      this.detail.typ = val.typ;
      this.detail.startDate = this.dateTimeFull(val.startDate);
      this.detail.expiredDate = this.dateTimeFull(val.expiredDate);
      this.detail.status = val.status;
      this.detail.createTime = this.dateTimeFull(val.createTime);
      this.detail.updateTime = this.dateTimeFull(val.updateTime);
    },

    editClick(type, id, docId) {
      localStorage.setItem("typelegal", type);
      // console.log('homepage',type)
      this.$router.push({
        path: "/superadmin/legal/legalitas/detail/update/" + id + "/" + docId,
      });
    },

    // handle selain typ PKS
    handleBlobResponse(blobFileData, item, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Dokumen " + item.typ + " " + item.name + "." + extension
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    // handle PKS dokumen
    handleBlobResponse1(blobFileData, item, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Dokumen PKS Legal " + item.name + "." + extension
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    // handle PKS SK Pengurus
    handleBlobResponse2(blobFileData, item, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Dokumen PKS " + "SK Pengurus" + " " + item.name + "." + extension
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    // handle PKS dokumen operasional
    handleBlobResponse3(blobFileData, item, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Dokumen PKS " + "Operasional" + " " + item.name + "." + extension
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    downloadItem(item, extension) {
      const toast = useToast();
      this.isLoading = true;
      ApiService.getDownload(
        "crmv2/main_legal/legal/legal/download_document_legality/" +
        item._id +
        "/" +
        item.documentId
      )
        .then((response) => {
          this.handleBlobResponse(response.data, item, extension);
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },

    async downloadItem1(item, extension) {
      console.log(extension);
      const toast = useToast();
      // if(extension == '-'){
      //   toast.error("Gagal Unduh, File Tidak Ada");
      // }else{

      // }
      this.isLoading = true;

      ApiService.getDownload(
        "crmv2/main_legal/legal/legal/download_document_legality/" +
        item._id +
        "/" +
        item.documentId
      )
        .then((response) => {
          this.handleBlobResponse1(response.data, item, extension);
          // this.getExtensionFromDetailLegal('docurl',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse1(response.data, item, extension), 1000)});;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },

    async downloadItem2(item, extension) {
      console.log(extension);
      const toast = useToast();
      // console.log(extension);
      // if(extension == '-'){
      //   toast.error("Gagal Unduh, File Tidak Ada");
      // }else{

      // }
      this.isLoading = true;
      ApiService.getDownload(
        "crmv2/main_legal/legal/legal/download_document_sk_school/" +
        item._id +
        "/" +
        item.documentId
      )
        .then((response) => {
          this.handleBlobResponse2(response.data, item, extension);
          // this.getExtensionFromDetailLegal('docsk',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse2(response.data, item, this.getExtensionFromDetail), 1000)});;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },

    async downloadItem3(item, extension) {
      console.log(extension);
      const toast = useToast();
      // if(extension == '-'){
      //   toast.error("Gagal Unduh, File Tidak Ada");
      // }else{

      // }
      this.isLoading = true;
      ApiService.getDownload(
        "crmv2/main_legal/legal/legal/download_document_operational_school/" +
        item._id +
        "/" +
        item.documentId
      )
        .then((response) => {
          this.handleBlobResponse3(response.data, item, extension);
          // this.getExtensionFromDetailLegal('docoperational',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse3(response.data, item, this.getExtensionFromDetail), 1000)});;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },

    // getExtensionFromDetailLegal(type,idSekolah,idDokumen){
    //   const toast = useToast();
    //     // var getExtensionFromDetail = '.docx'
    //     ApiService.getWithoutSlug(
    //     "crmv2/main_legal/legal/legal/get_document_legal/" +
    //       idSekolah +
    //       "/" +
    //       idDokumen
    //     )
    //     .then((res) => {
    //       // console.log(res.data.documentLegality);
    //       switch (type) {
    //         case "docurl":
    //           this.getExtensionFromDetail = this.getExtensionFile(res.data.documentLegality.documentUrl)
    //           break;
    //         case "docsk":
    //           this.getExtensionFromDetail = this.getExtensionFile(res.data.documentLegality.documentSkPengurus)
    //           break;
    //         case "docoperational":
    //           this.getExtensionFromDetail = this.getExtensionFile(res.data.documentLegality.documentSchoolOperational)
    //           break;
    //       }
    //       // console.log('doc',res.data.documentLegality.documentUrl)
    //     })
    //     .catch((e) => {
    //       toast.error(e.response.data.detail);
    //     });
    // },

    changeModal() { },

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    dateTimeFull(val) {
      if (val) {
        let text = val;
        let result = text.includes("T");
        let jam = "";
        if (result == true) {
          // let text = "2022-04-05T09:45:32.719896";
          let val = text.split("T");
          let subst = val[1].substr(0, 8);
          jam = subst;
        } else {
          // jam = "hh:mm";
        }
        return moment(val).format("YYYY-MM-DD " + jam);
      } else {
        return "-";
      }
    },
    getExtensionFile(val) {
      if (val) {
        const myArray = val.split(".");
        return myArray[1];
      } else {
        return "-";
      }
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }, 1000)
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
});
